/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TempCredentialResource {
    AWS_STORAGE_TOKEN = 'aws-storage-token',
    CELLO_TOKEN = 'cello-token',
    ENTRI_TOKEN = 'entri-token',
    FILE_RESOLVER_TOKEN = 'file-resolver-token',
    GETSTREAM_TOKEN = 'getstream-token',
    PLAID_BANK_TOKEN = 'plaid-bank-token',
}
