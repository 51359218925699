import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const DotIcon: React.FC<IIconProps> = ({ className = '', color = 'currentColor', width = 20 }) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path d="M10 13.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666z" fill={color} />
  </svg>
);
export { DotIcon };
