import React, { useId } from 'react';

import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '@bloom/ui/components/Icons/Check';
import { DotIcon } from '@bloom/ui/components/Icons/Dot';

interface IPropsBase
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  'data-testid': string;
  invalid?: boolean;
  label?: React.ReactNode;
  type?: 'checkbox' | 'radio';
}

interface IPropsWithBooleanValue extends IPropsBase {
  onChange: (name: string, value: boolean) => void;
  value?: boolean;
}

interface IPropsWithStringValue extends IPropsBase {
  onChange: (name: string, value: string) => void;
  value: string;
}

type TProps = IPropsWithBooleanValue | IPropsWithStringValue;

const Checkbox = React.forwardRef<HTMLInputElement, TProps>((props, forwardedRef) => {
  const {
    checked = false,
    className,
    disabled,
    id,
    invalid = false,
    label,
    name,
    onChange,
    type = 'checkbox',
    value,
    ...rest
  } = props;

  const reactId = useId();

  const htmlId = id || reactId;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (typeof value === 'undefined') {
      onChange(name, e.target.checked);
    } else {
      onChange(name, value);
    }
  }

  return (
    <div className={twMerge('group/checkbox flex items-start gap-3', className)}>
      <div className="relative mt-0.5 inline-flex text-white dark:text-black">
        <input
          className={twMerge(
            'h-6 w-6 appearance-none rounded-sm border',
            checked && !invalid && !disabled ? 'bg-black dark:bg-white' : '',
            disabled && !checked ? 'bg-black-15 dark:bg-white-15' : '',
            disabled && checked ? 'bg-black-50 dark:bg-white-50' : '',
            checked && invalid && !disabled ? 'bg-red' : '',
            !checked && !invalid && !disabled ? 'bg-transparent' : '',

            checked && !invalid ? 'border-black dark:border-white' : '',
            (!checked || disabled) && !invalid
              ? 'border-black-15 hover:border-black-50 dark:border-white-15 hover:dark:border-white-50'
              : '',
            invalid ? 'border-danger' : '',

            disabled ? 'cursor-not-allowed' : '',
            !disabled ? 'cursor-pointer' : '',

            !disabled && !invalid
              ? 'group-hover/checkbox:border-black-50 dark:group-hover/checkbox:border-white-50'
              : '',
            type === 'checkbox' ? 'rounded-sm' : '',
            type === 'radio' ? 'rounded-full' : ''
          )}
          name={name}
          {...rest}
          checked={checked}
          disabled={disabled}
          id={htmlId}
          onChange={handleChange}
          ref={forwardedRef}
          type={type}
          value={checked ? 'on' : 'off'}
        />
        {type === 'checkbox' ? (
          <>
            {checked ? (
              <CheckIcon
                className="pointer-events-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                color="var(--color-input-appearance)"
              />
            ) : null}
          </>
        ) : null}
        {type === 'radio' ? (
          <>
            {checked ? (
              <DotIcon
                className="pointer-events-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                color="var(--color-input-appearance)"
              />
            ) : null}
          </>
        ) : null}
      </div>

      {label ? (
        typeof label === 'string' ? (
          <label
            className={twMerge(
              'mt-1 font-medium text-black dark:text-white',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              invalid ? 'text-danger' : 'text-inherit'
            )}
            htmlFor={htmlId}
          >
            {label}
          </label>
        ) : (
          label
        )
      ) : null}
    </div>
  );
});

export { Checkbox };
