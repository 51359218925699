'use client';
import React, { useId, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { mergeRefs } from '@bloom/ui/utils/misc';

import { LoaderSizeEnum, LoaderV2 } from '@bloom/library/components/LoaderV2';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  Control?: React.ReactNode | null;
  controlWrapperClassName?: string;
  ['data-testid']: string;
  errorMessage?: string;
  inputClassName?: string;
  invalid?: boolean;
  label?: React.ReactNode;
  labelClassName?: string;
  legendClassName?: string;
  loading?: boolean;
  onValueChange?: (value: string) => void;
}

const Input = React.forwardRef<HTMLInputElement, IProps>((props, forwardedRef) => {
  const {
    autoComplete,
    children,
    className = '',
    Control,
    controlWrapperClassName,
    disabled,
    errorMessage,
    id,
    inputClassName,
    invalid,
    label,
    labelClassName,
    legendClassName,
    loading,
    onChange,
    onValueChange,
    placeholder,
    readOnly,
    type = 'text',
    ...restInputProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const defaultId = useId();
  const htmlId = id || defaultId;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onValueChange) {
      onValueChange(e.target.value);
    }

    if (onChange) {
      onChange(e);
    }
  }

  return (
    <fieldset
      className={twMerge(
        'relative h-11 shrink-0 rounded-sm border',
        !!errorMessage || invalid ? 'border-danger text-danger' : '',
        readOnly || disabled ? 'border-dashed' : '',

        !errorMessage && !invalid ? 'border-black-15 hover:border-black-50' : '',
        !errorMessage && !invalid ? 'dark:border-white-15 dark:hover:border-white-50' : '',
        !errorMessage && !invalid ? 'has-[:focus]:border-black' : '',
        !errorMessage && !invalid ? 'has-[:focus]:dark:border-white' : '',

        className
      )}
    >
      <input
        aria-invalid={!!errorMessage || invalid}
        className={twMerge(
          'border-none bg-transparent',
          'font-regular peer h-11 w-full flex-1 appearance-none rounded-sm px-4 outline-hidden transition-colors delay-300 placeholder:italic',
          'placeholder:text-black-50 dark:placeholder:text-white-50 text-black dark:text-white',
          'text-base placeholder:text-sm md:text-sm',

          label ? 'placeholder:focus:text-transparent dark:focus:placeholder:text-transparent' : '',
          !!Control || loading ? 'pr-10' : '',

          inputClassName
        )}
        disabled={disabled}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        {...restInputProps}
        autoComplete={type === 'password' ? 'off' : autoComplete}
        id={htmlId}
        ref={mergeRefs(inputRef, forwardedRef)}
      />

      <legend
        className={twMerge(
          'pointer-events-none ml-3 h-0 overflow-hidden text-xs text-transparent duration-200',
          'px-1 peer-placeholder-shown:px-0 peer-focus:px-1',
          'text-xs peer-placeholder-shown:text-[0] peer-focus:text-xs',
          'w-auto peer-placeholder-shown:w-0 peer-focus:w-auto',
          !label ? '!px-0' : '',
          legendClassName
        )}
      >
        {label}
      </legend>

      {Control || loading ? (
        <span
          className={twMerge(
            'pointer-events-none absolute top-1/2 right-3 inline-flex -translate-y-1/2',
            !errorMessage && !invalid && !readOnly
              ? 'text-grey peer-hover:text-black peer-focus:text-black'
              : '',
            controlWrapperClassName
          )}
        >
          {loading ? <LoaderV2 size={LoaderSizeEnum.XS} variant="dark" /> : null}
          {Control
            ? React.isValidElement(Control)
              ? React.cloneElement(Control, {
                  width: 16,
                  ...Control.props,
                  className: twMerge(Control.className, loading ? 'invisible' : 'loading'),
                })
              : null
            : null}
        </span>
      ) : null}

      <label
        className={twMerge(
          'absolute top-0 left-4 z-10 max-w-[calc(100%-1.25rem)] -translate-y-1/2 cursor-text truncate text-xs not-italic duration-150',
          'peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-placeholder-shown:text-sm peer-placeholder-shown:italic',
          'peer-focus:top-0 peer-focus:left-4 peer-focus:text-xs peer-focus:not-italic',

          !!errorMessage || invalid ? 'border-danger text-danger' : '',
          label ? 'opacity-100 peer-placeholder-shown:opacity-0 peer-focus:opacity-100' : '',
          !errorMessage && !invalid && !readOnly
            ? 'text-black-50 dark:text-white-50 peer-focus:text-black dark:peer-focus:text-white'
            : '',
          labelClassName
        )}
        htmlFor={htmlId}
        title={typeof label === 'string' ? label : undefined}
      >
        {label}
      </label>

      {errorMessage ? (
        <p
          className="text-danger bg-red-light absolute top-full left-0 mt-1 max-w-full truncate rounded-sm px-1 py-0.5 text-xs"
          title={errorMessage}
        >
          {errorMessage}
        </p>
      ) : null}

      {children}
    </fieldset>
  );
});

export { Input };
