import { DiscountItemResponse } from '@bloom/codegen/models/DiscountItemResponse';
import { InvoiceResponse } from '@bloom/codegen/models/InvoiceResponse';
import { OrderItemResponse } from '@bloom/codegen/models/OrderItemResponse';
import { PaymentRetainerResponse } from '@bloom/codegen/models/PaymentRetainerResponse';
import { ProjectTemplateItemResponse } from '@bloom/codegen/models/ProjectTemplateItemResponse';
import { PublicInvoiceResponse } from '@bloom/codegen/models/PublicInvoiceResponse';

import { IInvoiceItem, IProjectPackage } from '@bloom/library/types/project';

import { round } from './misc';

/*
 * Note that project.package.discount has different structure,
 * but it's normalized in ProjectPackage component.
 *
 * @param {Object} discount contains type and amount properties.
 * @param {number} total is cost of a package __before__ taxes.
 */
export function getDiscountAmount(discount: DiscountItemResponse, total = 0) {
  const { amount, type } = discount || {};

  let discountAmount = 0;
  if (type === 'MONEY') {
    discountAmount = parseFloat(amount) || 0;
  } else if (type === 'PERCENT') {
    const percentage = parseFloat(amount) || 0;
    discountAmount = (total || 0) * (percentage / 100);
  }

  return discountAmount;
}

/*
 * Note that project.package.discount has different structure,
 * but it's normalized in ProjectPackage component.
 *
 * @param {Object} discount contains type and amount properties.
 * @param {number} total is cost of a package __before__ taxes.
 */
export function getDiscountRatio(discount: DiscountItemResponse | null, total = 0) {
  if (discount) {
    //* See the comment in the function above (getDiscountAmount).
    const { amount, type } = discount || {};

    if (type === 'PERCENT') {
      const percentage = parseFloat(amount) || 0;
      return percentage / 100;
    }

    const discountAmount = parseFloat(amount) || 0;
    return total ? discountAmount / total : 0;
  }

  return 0;
}

export function getMinimumDue(paymentRetainer: PaymentRetainerResponse | null, total: number) {
  const amount = parseFloat(paymentRetainer?.retainAmount || '0') || 0;

  return amount ? Math.min(amount, total) : total;
}

/*
 * @param {string} taxPercent tax amount in per cent.
 * @param {number} total is cost of a package.
 */
export function getTaxAmount(taxPercent: string, total: number) {
  const taxPercentage = parseFloat(taxPercent) || 0;
  // Make sure it always a valid number.
  return Math.max((total * taxPercentage) / 100, 0);
}

export function getTaxAmountIncluded(total: number, taxPercent: string) {
  const taxPercentage = parseFloat(taxPercent) || 0;
  const taxAmount = (total * taxPercentage) / (100 + taxPercentage);

  return Math.max(taxAmount, 0);
}

export function getPaymentDueDate(invoice: InvoiceResponse | PublicInvoiceResponse) {
  const invoiceDueDate = invoice.dueDate;
  const paymentRetainer =
    'package' in invoice ? invoice.package.paymentRetainer : invoice.paymentRetainer;
  const isRetainerPaid =
    'package' in invoice ? invoice.package.isRetainerPaid : invoice.isRetainerPaid;
  const minimumDue = 'package' in invoice ? invoice.package.minimumDue : invoice.minimumDue;

  // payment retainer can be null
  const { paymentDueDate: retainerDueDate } = paymentRetainer || {};

  if (parseFloat(minimumDue) === 0) {
    return null;
  }

  if (isRetainerPaid) {
    return invoiceDueDate || null;
  }
  if (retainerDueDate) {
    return retainerDueDate;
  }

  return null;
}

export function getItemListTotals(
  items: Array<IInvoiceItem | OrderItemResponse | ProjectTemplateItemResponse>,
  taxPercent = '0'
) {
  let subtotal = 0;
  let total = 0;
  let totalTaxAmount = 0;
  let taxAmountIncluded = 0;
  let taxableAmount = 0;
  let taxableInTotalAmount = 0;

  items.forEach((item) => {
    let unitPrice = 0;
    if ('unitPrice' in item) {
      unitPrice = parseFloat(item.unitPrice) || 0;
    } else if ('product' in item) {
      unitPrice = parseFloat(item.product.unitPrice) || 0;
    }

    const quantity = item.quantity || 0;
    const itemTotal = unitPrice * quantity;
    total = total + itemTotal;
    subtotal = subtotal + itemTotal;

    if (item.isTaxable) {
      if (item.totalIncludesTaxes) {
        const taxIncludedInItem = getTaxAmountIncluded(itemTotal, taxPercent);
        taxAmountIncluded = taxAmountIncluded + taxIncludedInItem;
        taxableInTotalAmount = taxableInTotalAmount + itemTotal;
      } else {
        taxableAmount = taxableAmount + itemTotal;
        totalTaxAmount += getTaxAmount(taxPercent, itemTotal);
        total += getTaxAmount(taxPercent, itemTotal);
      }
    }
  });

  return {
    subtotal,
    taxableAmount,
    taxableInTotalAmount,
    taxAmountIncluded,
    total,
    totalTaxAmount,
  };
}

export function getInstantBookingPackagePaymentDetails(
  pkg: IProjectPackage,
  selectedAddons: Array<OrderItemResponse | { slug: string }> = [],
  taxPercent = '0'
) {
  if (pkg) {
    const {
      subtotal: pkgSubtotal,
      taxableAmount: pkgTaxableAmount,
      taxableInTotalAmount: pkgTaxableInTotalAmount,
      taxAmountIncluded: pgkTaxIncluded,
      totalTaxAmount: pkgTaxTotal,
    } = getItemListTotals(pkg.items, taxPercent);

    const {
      subtotal: addonSubtotal,
      taxableAmount: addonTaxableAmount,
      taxableInTotalAmount: addonTaxableInTotalAmount,
      taxAmountIncluded: addonTaxIncluded,
      totalTaxAmount: addonTaxTotal,
    } = getItemListTotals(selectedAddons, taxPercent);

    const subtotal = pkgSubtotal + addonSubtotal;

    const totalTax = pkgTaxTotal + addonTaxTotal;

    const taxIncludedInTotal = pgkTaxIncluded + addonTaxIncluded;
    const taxableInTotalAmountTotal = pkgTaxableInTotalAmount + addonTaxableInTotalAmount;
    const taxableAmountTotal = pkgTaxableAmount + addonTaxableAmount;

    // Calculate discount before adding taxes.
    const discountAmount = getDiscountAmount(pkg.discount, subtotal);
    const discountRatio = getDiscountRatio(pkg.discount, subtotal);

    let total = subtotal - subtotal * discountRatio;

    // Tax
    const taxAmount = totalTax - totalTax * discountRatio;
    const taxableAmount = taxableAmountTotal - taxableAmountTotal * discountRatio;
    const taxableInTotalAmount =
      taxableInTotalAmountTotal - taxableInTotalAmountTotal * discountRatio;

    total = total + taxAmount;

    total = Math.max(total, 0);

    const minimumDue = getMinimumDue(pkg.paymentRetainer, total);

    return {
      addonsCost: Number(addonSubtotal.toFixed(2)),
      discountAmount: Number(discountAmount.toFixed(2)),
      discountRatio: Number(discountRatio.toFixed(2)),
      minimumDue: Number(minimumDue.toFixed(2)),
      packageCost: Number(pkgSubtotal.toFixed(2)),
      subtotal: Number(subtotal.toFixed(2)),
      taxableAmount: Number((taxableAmount - taxableAmount * discountRatio).toFixed(2)),
      taxableInTotalAmount: Number(
        (taxableInTotalAmount - taxableInTotalAmount * discountRatio).toFixed(2)
      ),
      taxAmount: Number(taxAmount.toFixed(2)),
      taxIncludedInTotal: Number(taxIncludedInTotal.toFixed(2)),
      total: Number(total.toFixed(2)),
      totalExcludingTax: taxIncludedInTotal
        ? Number((subtotal - taxIncludedInTotal).toFixed(2))
        : 0,
    };
  }
  return {
    addonsCost: 0,
    discountAmount: 0,
    discountRatio: 0,
    minimumDue: 0,
    packageCost: 0,
    subtotal: 0,
    taxableAmount: 0,
    taxableInTotalAmount: 0,
    taxAmount: 0,
    taxIncludedInTotal: 0,
    total: 0,
    totalExcludingTax: 0,
  };
}

/*
  getInvoicePaymentDetails

  This is primarily used in the project invoice creator, but also used in the preview pages.
  e.g.: payment page, email preview, invoice pdf preview
*/
export function getInvoicePaymentDetails(
  invoice: PublicInvoiceResponse | InvoiceResponse,
  taxPercent = '0'
) {
  if (invoice) {
    const { amountReceived } = invoice;

    const items = 'package' in invoice ? invoice.package.items : invoice.items;
    const discount = 'package' in invoice ? invoice.package.discount : invoice.discount;
    const paymentRetainer =
      'package' in invoice ? invoice.package.paymentRetainer : invoice.paymentRetainer;

    const { subtotal, taxableAmount, taxableInTotalAmount, taxAmountIncluded, totalTaxAmount } =
      getItemListTotals(items, taxPercent);
    const invoiceSubtotal = subtotal;

    // Calculate discount before adding taxes.
    const discountRatio = getDiscountRatio(discount, invoiceSubtotal);
    const discountAmount = getDiscountAmount(discount, invoiceSubtotal);

    let invoiceTotal = round(subtotal - subtotal * discountRatio);
    // Tax (with discount ratio applied)
    const taxAmount = round(totalTaxAmount - totalTaxAmount * discountRatio);

    invoiceTotal = invoiceTotal + taxAmount;

    invoiceTotal = Math.max(invoiceTotal, 0);

    const amountReceivedNumber = parseFloat(amountReceived || '0') || 0;
    const depositAmount = parseFloat(paymentRetainer?.retainAmount || '0') || 0;
    const remainingDeposit = Math.max(0, depositAmount - amountReceivedNumber);

    const balanceDue = remainingDeposit
      ? remainingDeposit
      : Math.max(0, invoiceTotal - Number(amountReceived || 0));

    return {
      balanceDue: Number(balanceDue.toFixed(2)),
      discountAmount: Number(discountAmount.toFixed(2)),
      discountRatio: Number(discountRatio.toFixed(2)),
      subtotal: invoiceSubtotal,
      taxableAmount: Number((taxableAmount - taxableAmount * discountRatio).toFixed(2)),
      taxableInTotalAmount: Number(
        (taxableInTotalAmount - taxableInTotalAmount * discountRatio).toFixed(2)
      ),
      taxAmount: Number(taxAmount.toFixed(2)),
      taxAmountIncluded: Number(taxAmountIncluded.toFixed(2)),
      total: Number(invoiceTotal.toFixed(2)),
      totalExcludingTax: taxAmountIncluded ? Number((subtotal - taxAmountIncluded).toFixed(2)) : 0,
    };
  }

  return {
    balanceDue: 0,
    discountAmount: 0,
    discountRatio: 0,
    subtotal: 0,
    taxableAmount: 0,
    taxableInTotalAmount: 0,
    taxAmount: 0,
    taxAmountIncluded: 0,
    total: 0,
    totalExcludingTax: 0,
  };
}
