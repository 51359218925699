import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const BloomTextLogo: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 93,
}) => {
  const height = (+width * 16) / 51;
  return (
    <svg className={className} fill="none" height={height} viewBox="0 0 51 16" width={width}>
      <path
        d="M6.7 4.453c-1.232 0-2.199.451-2.92 1.204v-1.92a1.983 1.983 0 0 0-1.99-1.975V9.25c0 2.653 2.105 4.91 4.778 4.986 2.787.075 5.081-2.145 5.081-4.892-.019-2.69-2.237-4.891-4.948-4.891Zm0 7.789c-1.61 0-2.92-1.298-2.92-2.898 0-1.599 1.31-2.916 2.92-2.916 1.612 0 2.92 1.298 2.92 2.898 0 1.599-1.308 2.916-2.92 2.916ZM14.64 14.238V4.191a1.983 1.983 0 0 0-1.99-1.976v10.066c0 1.073.89 1.957 1.99 1.957ZM20.57 4.454c-2.712 0-4.93 2.202-4.93 4.892 0 2.69 2.218 4.892 4.93 4.892 2.71 0 4.929-2.201 4.929-4.892 0-2.69-2.2-4.892-4.93-4.892Zm0 7.79c-1.612 0-2.92-1.299-2.92-2.898 0-1.599 1.308-2.916 2.92-2.916 1.61 0 2.938 1.317 2.938 2.916 0 1.6-1.308 2.898-2.939 2.898ZM31.429 4.454c-2.712 0-4.93 2.202-4.93 4.892 0 2.69 2.218 4.892 4.93 4.892 2.71 0 4.93-2.201 4.93-4.892 0-2.69-2.22-4.892-4.93-4.892Zm0 7.79c-1.612 0-2.92-1.299-2.92-2.898 0-1.599 1.308-2.916 2.92-2.916 1.611 0 2.92 1.298 2.92 2.897 0 1.6-1.309 2.917-2.92 2.917ZM45.72 4.906c-1.081.019-1.877.546-2.408 1.355-.493-.828-1.365-1.355-2.503-1.355-1.915.019-3.45 1.618-3.45 3.537v3.82c0 1.09.89 1.975 1.99 1.975V8.405c0-.771.588-1.467 1.365-1.505a1.475 1.475 0 0 1 1.574 1.468v3.894c0 1.092.891 1.976 1.99 1.976V8.405c0-.771.589-1.467 1.366-1.505a1.475 1.475 0 0 1 1.574 1.468v3.894c0 1.092.89 1.976 1.99 1.976V8.443c.057-1.938-1.535-3.575-3.488-3.537Z"
        fill={color}
      />
    </svg>
  );
};

export { BloomTextLogo };
