import React from 'react';

import { IIconProps } from './types';

const PlusIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  rotate = 0,
  width = 20,
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M10 3.333c.46 0 .833.373.833.834v5h5a.833.833 0 110 1.666h-5v5a.833.833 0 01-1.666 0v-5h-5a.833.833 0 010-1.666h5v-5c0-.46.373-.834.833-.834z"
      fill={color}
      fillRule="evenodd"
      transform={`rotate(${rotate} 10 10)`}
    />
  </svg>
);
export { PlusIcon };
