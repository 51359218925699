import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const LockIcon: React.FC<IIconProps> = ({ width = 18, className = '', color = 'currentColor' }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={+width * (20 / 18)}
      viewBox="0 0 18 20"
      width={width}
    >
      <path
        clipRule="evenodd"
        d="M9 2.5a3.333 3.333 0 00-3.333 3.333v2.5h6.666v-2.5A3.333 3.333 0 009 2.5zm5 5.834v-2.5a5 5 0 00-10 0v2.5h-.833a2.5 2.5 0 00-2.5 2.5v5.833a2.5 2.5 0 002.5 2.5h11.666a2.5 2.5 0 002.5-2.5v-5.834a2.5 2.5 0 00-2.5-2.5H14zM3.167 10a.833.833 0 00-.834.834v5.833c0 .46.374.833.834.833h11.666c.46 0 .834-.373.834-.833v-5.834a.833.833 0 00-.834-.833H3.167z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export { LockIcon };
