import React from 'react';

import { twMerge } from 'tailwind-merge';

interface IProps extends Omit<React.HTMLAttributes<HTMLElement>, 'onClick'> {
  className?: string;
  'data-testid': string;
  disabled?: boolean;
  invalid?: boolean;
  name: string;
  onClick: (name: string, value: unknown) => void;
  selected: boolean;
  value: number | string | boolean;
}

export const SelectBox = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IProps>>(
  (props, forwardRef) => {
    const { children, className, disabled, invalid, name, onClick, selected, value, ...restProps } =
      props;

    function handleClick() {
      if (!disabled) {
        onClick(name, value);
      }
    }

    return (
      <div
        aria-selected={selected}
        className={twMerge(
          'cursor-pointer rounded-sm border p-4',
          disabled ? 'bg-black-15 dark:border-white-15' : '',
          (disabled && !invalid) || (!selected && !disabled && !invalid)
            ? 'border-black-15 dark:border-white-15'
            : '',
          !selected && invalid ? 'border-danger' : '',
          selected && invalid ? 'border-danger shadow-outline-red' : '',
          !disabled && !invalid ? 'hover:border-black-50 dark:hover:border-white-50' : '',
          selected && !disabled && !invalid
            ? 'shadow-outline dark:shadow-outline-white border-black dark:border-white'
            : '',
          selected ? 'text-body dark:text-white' : 'text-black-50 dark:text-white-50',
          className
        )}
        data-testid={props['data-testid']}
        onClick={handleClick}
        ref={forwardRef}
        role="radio"
        {...restProps}
      >
        {children}
      </div>
    );
  }
);
