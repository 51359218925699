import { ContractVariableResponse } from '@bloom/codegen/models/ContractVariableResponse';

import {
  ClientInfoDisplayNameMap,
  ClientInfoFieldEnum,
  ClientInfoFieldNames,
  ClientInfoTokenMap,
} from '@bloom/library/types/templates';

export const replaceTokensWithValues = (html: string, vars: Array<ContractVariableResponse>) => {
  if (!vars) {
    console.error('Template variables are required');
    return '';
  }

  vars.forEach((v) => {
    html = html.replace(new RegExp(v.injectionToken, 'g'), v.currentValue);
  });

  return html;
};

export function createContractVariablesFromClientInfo(
  clientInfo: {
    [k in ClientInfoFieldNames]?: string;
  } = {}
): Array<ContractVariableResponse> {
  // dont loop over client info, rather use the enum to ensure we get all the fields
  const clientVariables = [
    ClientInfoFieldEnum.FIRST_NAME,
    ClientInfoFieldEnum.LAST_NAME,
    ClientInfoFieldEnum.PHONE_NUMBER,
    ClientInfoFieldEnum.EMAIL_ADDRESS,
    ClientInfoFieldEnum.COMPANY,
  ].map((field) => ({
    currentValue: clientInfo[field] || ClientInfoDisplayNameMap[field] || '',
    displayName: ClientInfoDisplayNameMap[field] || '',
    injectionToken: ClientInfoTokenMap[field] || '',
  }));

  // build the full name variable if first or last name exist
  clientVariables.push({
    currentValue:
      [clientInfo[ClientInfoFieldEnum.FIRST_NAME], clientInfo[ClientInfoFieldEnum.LAST_NAME]]
        .join(' ')
        .trim() || ClientInfoDisplayNameMap[ClientInfoFieldEnum.FULL_NAME],
    displayName: ClientInfoDisplayNameMap[ClientInfoFieldEnum.FULL_NAME],
    injectionToken: ClientInfoTokenMap[ClientInfoFieldEnum.FULL_NAME],
  });

  return clientVariables || [];
}
