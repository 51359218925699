import React from 'react';

import { twMerge } from 'tailwind-merge';

import { BloomTextLogo } from '@bloom/ui/components/Icons/BloomTextLogo';

const PoweredBy: React.FC<
  React.PropsWithChildren<
    React.AnchorHTMLAttributes<HTMLAnchorElement> & {
      // Keep isBrandingRemoved as required
      // so we do not forget to remove it with teams package
      isBrandingRemoved: boolean;
      logoWidth?: number;
      utm_medium: string;
    }
  >
> = (props) => {
  const {
    children,
    className,
    isBrandingRemoved,
    logoWidth = 49,
    utm_medium,
    ...restProps
  } = props;

  if (isBrandingRemoved) {
    return null;
  }

  return (
    <a
      className={twMerge(
        'font-regular text-grey inline-flex items-center gap-2 text-xs hover:underline',
        className
      )}
      data-testid="powered-by-link"
      href={`https://bloom.io/?utm_source=poweredby&utm_medium=${utm_medium}`}
      rel="noopener"
      target="blank"
      {...restProps}
    >
      Powered by
      <BloomTextLogo width={logoWidth} />
      {children}
    </a>
  );
};

export { PoweredBy };
