import moment from 'moment';

export function convertToMinutes(duration: string) {
  const [hours = 0, minutes = 0] = duration.split(':');
  return Number(hours) * 60 + Number(minutes);
}

export function formatDuration(duration: string) {
  const [hours = 0, minutes = 0] = duration.split(':').map((part) => Number(part));

  let str = '';
  if (hours > 0) {
    str += `${Number(hours)}hr${hours > 1 ? 's' : ''}`;
  }
  if (hours > 0 && minutes > 0) {
    str += ' ';
  }
  if (minutes > 0) {
    str += `${Number(minutes)}min`;
  }

  return str;
}

export function formatDurationFull(duration: string) {
  const [hours = 0, minutes = 0] = duration.split(':').map((part) => Number(part));
  let str = '';
  if (hours > 0) {
    str += `${Number(hours)} hour${hours > 1 ? 's' : ''}`;
  }
  if (hours > 0 && minutes > 0) {
    str += ' ';
  }
  if (minutes > 0) {
    str += `${Number(minutes)} minute${minutes > 1 ? 's' : ''}`;
  }

  return str;
}

export function formatEventDuration(event) {
  let projectDuration = '';
  const { endDate, isAllDay, startDate } = event || {};
  if (startDate && endDate) {
    if (isAllDay) {
      projectDuration = 'All day';
    } else {
      const hours = moment(endDate).diff(moment(startDate), 'hours');
      const minutes = moment(endDate).diff(moment(startDate), 'minutes') % 60;
      if (hours > 0 || minutes > 0) {
        projectDuration = formatDurationFull(`${hours}:${minutes}`);
      }
    }
  }

  return projectDuration;
}
