import React from 'react';

import { twMerge } from 'tailwind-merge';

import { ContractVariableResponse } from '@bloom/codegen/models/ContractVariableResponse';

import { renderTiptapToHtml } from '@bloom/library/components/Tiptap/template.server';
import { replaceTokensWithValues } from '@bloom/library/utils/contracts';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  body: string;
  contractVariables: Array<ContractVariableResponse>;
}

const ContractBody: React.FC<IProps> = (props) => {
  const { body, className, contractVariables, ...restProps } = props;

  const tiptapContent = replaceTokensWithValues(
    renderTiptapToHtml(body, { variableAsToken: true }),
    contractVariables
  );

  return (
    <div
      className={twMerge('space-y-3', className)}
      dangerouslySetInnerHTML={{ __html: tiptapContent }}
      id="bloom-wysiwyg"
      {...restProps}
    />
  );
};

export { ContractBody };
